<template>
  <a-page-header @back="() => $router.go(-1)" title="INDICADORES" class="pt-0 px-0">
    <template #extra>
      <a-breadcrumb>
        <a-breadcrumb-item><router-link to="/menu">Proyecto</router-link></a-breadcrumb-item>
        <a-breadcrumb-item>Indicador</a-breadcrumb-item>
      </a-breadcrumb>
    </template>
  </a-page-header>

  <div class="flex flex-wrap mb-4">
    <fieldset
      class="border border-solid w-full mt-3 border-gray-300 p-3 px-8 flex justify-center md:mt-0 lg:w-auto"
    >
      <legend class="text-sm pl-4">Filtros</legend>
      <IndicadorFiltros @reloadFetch="reloadFetch" @setPageNumber="setPageNumber" />
    </fieldset>

    <fieldset
      class="border border-solid w-full border-gray-300 flex justify-center items-center p-3 px-8 lg:w-auto"
    >
      <legend class="text-sm pl-4">Acciones</legend>

      <IndicadorAcciones
        :isDisabled="isDisabled"
        :indicador="indicador"
        :objPrintIndicador="objPrintIndicador"
        @handleChangeState="handleChangeState"
        @handleAdd="handleAdd"
        @handleCode="handleCode"
        @handleEdit="handleEdit"
        @handleDelete="handleDelete"
        @handlePeriodoMasivo="handlePeriodoMasivo"
      />
    </fieldset>
  </div>

  <div class="max-w-full px-4 py-4 mx-auto overflow-x-auto">
    <a-table
      :row-selection="rowSelection"
      :columns="columns"
      :data-source="indicadores"
      size="small"
      :pagination="false"
      rowKey="idindicador"
      :loading="isLoading"
      :rowClassName="
        (record, index) =>
          index % 2 === 0 ? 'cursor-pointer bg-white' : 'cursor-pointer bg-gray-100'
      "
    >
      <template #rangepages="{ index }">
        <p class="text-center">{{ (curretPage - 1) * 10 + index + 1 }}</p>
      </template>

      <template #idindicador="{ text: idindicador }">
        <p class="text-center font-bold">{{ idindicador }}</p>
      </template>

      <template #avance="{ text: avance }">
        <p class="text-center">{{ avance }} %</p>
      </template>

      <template #denominacion="{ record }">
        <a-tooltip>
          <template #title>Ver resumen de indicador </template>
          <router-link :to="'/indicador/' + record.idindicador">
            {{ record.denominacion }}
          </router-link>
        </a-tooltip>
      </template>

      <template #activo="{ text: activo }">
        <p class="text-center">
          <a-tag :color="activo === '1' ? 'green' : 'volcano'">
            {{ activo === "1" ? "Publicado" : "Ocultado" }}
          </a-tag>
        </p>
      </template>
    </a-table>
  </div>

  <footer class="flex flex-col md:flex-row items-center justify-between pb-6 pt-5 border-t-2">
    <div class="flex">
      <a-pagination v-model:current="curretPage" v-model:pageSize="pageSize" :total="totalDocs" />

      <button class="ml-4" @click="reloadFetch">
        <ReloadOutlined />
      </button>
    </div>
    <p class="py-3 md:py-0">Total de registros: {{ totalDocs }}</p>
  </footer>

  <div v-if="isCreateEdit">
    <IndicadorAddEdit
      v-if="isVisible"
      :isVisible="isVisible"
      :indicador="indicador"
      @closeModal="closeModal"
      @fetchData="fetchData"
    />
  </div>
  <div v-if="!isCreateEdit">
    <IndicadorCode
      v-if="isVisible"
      :indicador="indicador"
      :isVisible="isVisible"
      @closeModal="closeModal"
    />
  </div>

  <IndicadorPeriodoEvaluacionMasivo
    v-if="isPeriodoMasivo"
    :indicador="indicador"
    :isVisible="isVisible"
    @closeModal="closeModal"
  />

  <PrintIndicador :indicadores="indicadoresToPrint" />
</template>

<script>
import { ref, watch, reactive, computed, toRaw, onMounted, createVNode, onUnmounted } from "vue";
import { columns } from "./utilsIndicador";
import IndicadorCode from "./IndicadorCode.vue";
import IndicadorFiltros from "./IndicadorFiltros.vue";
import IndicadorAcciones from "./IndicadorAcciones.vue";
import IndicadorAddEdit from "./IndicadorAddEdit.vue";
import IndicadorPeriodoEvaluacionMasivo from "./IndicadorPeriodoEvaluacionMasivo.vue";
import PrintIndicador from "@/print/PrintIndicador.vue";
import UsuariosApi from "@/api/user";
import IndicadorApi from "@/api/indicador";
import { notification } from "ant-design-vue";
import { useStore } from "vuex";
import { ReloadOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import { calculaPorcentaje, semaforoByPorcentajeIndicador } from "@/utils";

export default {
  name: "AppIndicador",
  components: {
    IndicadorCode,
    IndicadorFiltros,
    IndicadorAddEdit,
    IndicadorAcciones,
    PrintIndicador,
    IndicadorPeriodoEvaluacionMasivo,
    // icons
    ReloadOutlined
  },
  setup() {
    const pageSize = ref(10);
    const store = useStore();
    const curretPage = ref(1);
    const idparametro = ref(1);
    const totalDocs = ref(null);
    const isVisible = ref(false);
    const isCreateEdit = ref(false);
    const isPeriodoMasivo = ref(false);
    const indicadores = ref([]);
    const indicador = ref(null);
    const isLoading = ref(false);
    const indicadoresToPrint = ref([]);
    const RANGO_SINDATO = ref(40);
    const RANGO_BAJO = ref(60);
    const RANGO_MEDIO = ref(80);
    const { establecimiento } = store.state.user.usuario;

    const objPrintIndicador = reactive({
      id: "printIndicador",
      popTitle: "SIVINUR | RESUMEN DE INDICADOR"
    });

    const state = reactive({
      selectedRowKeys: []
    });

    const isDisabled = reactive({
      edit: true,
      deleteAndChange: true
    });

    const fetchData = (currentPage = 1) => {
      const search = store.getters["indicador/search"];
      const filter = store.getters["indicador/filter"];

      isLoading.value = true;
      IndicadorApi.getAll({ currentPage, search, filter })
        .then((response) => {
          const indicadores_formated = response.data.docs.map((ind) => {
            const avance = calculaPorcentaje(ind.num, ind.den);
            const avanceColor = semaforoByPorcentajeIndicador(
              RANGO_SINDATO.value,
              RANGO_BAJO.value,
              RANGO_MEDIO.value,
              avance
            );

            return {
              ...ind,
              avance,
              avanceColor
            };
          });

          indicadores.value = indicadores_formated;
          totalDocs.value = response.data.totalDocs;
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading.value = false));
    };

    onMounted(() => {
      UsuariosApi.userLogAdd("Proyecto", "Indicadores", establecimiento);
      fetchData();
      fetchIndicadoresToPrint();
    });

    onUnmounted(() => {
      store.dispatch("indicador/cleanFilters");
    });

    const rowSelection = computed(() => {
      return {
        type: "radio",
        selectedRowKeys: state.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          state.selectedRowKeys = selectedRowKeys;
          indicador.value = toRaw(selectedRows[0]);
        }
      };
    });

    const clearSelectedRowkey = () => {
      state.selectedRowKeys = [];
    };

    watch(curretPage, () => {
      fetchData(curretPage.value);
    });

    watch(state, () => {
      isDisabled.edit = !state.selectedRowKeys.length;
      isDisabled.deleteAndChange = !state.selectedRowKeys.length;
    });

    watch(store.state.indicador, () => {
      fetchIndicadoresToPrint();
    });

    const handleAdd = () => {
      isCreateEdit.value = true;
      indicador.value = {
        idindicador: "",
        denominacion: "",
        tituloreporte: "",
        titulonumerado: "",
        titulodenominado: "",
        abreviatura: "",
        idtipofichatecnica: "",
        idquery: "",
        idgrupo: "",
        sigla: ""
      };

      showModal();
    };

    const handleEdit = () => {
      isCreateEdit.value = true;
      showModal();
    };

    const handleChangeState = () => {
      const message = {
        text: indicador.value.activo === "1" ? "ocultar" : "publicar",
        accion: indicador.value.activo === "1" ? "ocultará" : "publicará"
      };

      Modal.confirm({
        title: () => `¿Estás seguro de ${message.text} el indicador?`,
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () =>
          `Se ${message.accion} el indicador ${indicador.value.abreviatura}, este cambio afectará la vista pública`,
        okText: () => `Sí, ${message.text}`,
        okType: "danger",
        cancelText: () => "Cancelar",

        onOk() {
          const payload = {
            ids: toRaw(state.selectedRowKeys),
            activos: [indicador.value.activo === "1" ? "0" : "1"]
          };

          IndicadorApi.changeState(payload)
            .then(() => {
              reloadFetch();
              clearSelectedRowkey();
            })
            .catch((err) => console.log(err));
        }
      });
    };

    const handleCode = () => {
      const [idindicador] = state.selectedRowKeys;
      indicador.value = indicadores.value.find((p) => p.idindicador === idindicador);
      isCreateEdit.value = false;
      showModal();
    };

    const handleDelete = () => {
      Modal.confirm({
        title: () => "¿Estás seguro de eliminar?",
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () => `Se eliminará el indicador "${indicador.value.tituloreporte}"`,
        okText: () => "Sí, eliminar",
        okType: "danger",
        cancelText: () => "Cancelar",

        onOk() {
          const idIndicador = toRaw(state.selectedRowKeys)[0];
          IndicadorApi.deleteOne(idIndicador)
            .then(() => {
              reloadFetch();
              clearSelectedRowkey();
              notification.success({
                message: "Operación exitosa",
                description: "El indicador ha sido eliminado satisfactoriamente"
              });
            })
            .catch((err) => console.log(err));
        }
      });
    };

    const fetchIndicadoresToPrint = () => {
      const search = store.getters["indicador/search"];
      const filter = store.getters["indicador/filter"];

      IndicadorApi.getAll({ search, filter })
        .then((response) => {
          indicadoresToPrint.value = response.data;
        })
        .catch((err) => console.log(err));
    };

    const showModal = () => {
      isVisible.value = true;
    };

    const closeModal = () => {
      isVisible.value = false;
      isPeriodoMasivo.value = false;
    };

    const reloadFetch = () => {
      fetchData(curretPage.value);
    };

    const setPageNumber = (nroPage) => {
      curretPage.value = nroPage;
    };

    const handlePeriodoMasivo = () => {
      isPeriodoMasivo.value = true;
    };

    return {
      isLoading,
      handleCode,
      columns,
      indicadores,
      handleEdit,
      handleAdd,
      pageSize,
      handleDelete,
      curretPage,
      totalDocs,
      isVisible,
      idparametro,
      reloadFetch,
      isCreateEdit,
      isDisabled,
      indicador,
      showModal,
      closeModal,
      rowSelection,
      fetchData,
      handleChangeState,
      setPageNumber,
      indicadoresToPrint,
      objPrintIndicador,
      handlePeriodoMasivo,
      isPeriodoMasivo
    };
  }
};
</script>
