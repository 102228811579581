<template>
  <div class="flex flex-wrap gap-8 md:gap-5">
    <button @click="handleAdd" class="text-center">
      <div class="text-3xl text-green-500">
        <FileAddOutlined />
      </div>
      <p>Nuevo</p>
    </button>

    <button @click="handleEdit" class="text-center" :disabled="isDisabled.edit">
      <div
        class="text-3xl"
        :class="[isDisabled.edit ? 'text-gray-400 cursor-not-allowed' : 'text-blue-400']"
      >
        <FormOutlined />
      </div>
      <p>Editar</p>
    </button>

    <button @click="applyExportar" class="text-center">
      <div class="text-3xl text-green-700">
        <FileExcelOutlined />
      </div>
      <p>Exportar</p>
    </button>

    <button v-print="objPrintIndicador" class="text-center">
      <div class="text-3xl text-red-400">
        <FilePdfOutlined />
      </div>
      <p>Descargar</p>
    </button>

    <button @click="handleChangeState" class="text-center" :disabled="isDisabled.deleteAndChange">
      <div
        class="text-3xl"
        :class="[
          isDisabled.deleteAndChange ? 'text-gray-400 cursor-not-allowed' : 'text-yellow-400'
        ]"
      >
        <IssuesCloseOutlined />
      </div>
      <p>{{ actionMessage }}</p>
    </button>

    <button @click="handleCode" class="text-center" :disabled="isDisabled.edit">
      <div
        class="text-3xl"
        :class="[isDisabled.edit ? 'text-gray-400 cursor-not-allowed' : 'text-purple-400']"
      >
        <FileZipOutlined />
      </div>
      <p>Query</p>
    </button>

     <a-upload
      v-model:fileList="fileList"
      accept=".pdf"
      name="file"
      :multiple="false"
      :action="urlAction"
      :headers="headers"
      :before-upload="beforeUpload"
      @change="onChangeUpload"
    >
      <button class="text-center" :disabled="isDisabled.edit">
        <div
          class="text-3xl"
          :class="[isDisabled.edit ? 'text-gray-400 cursor-not-allowed' : 'text-red-400']"
        >
          <FilePdfOutlined />
        </div>
        <p>Subir</p>
      </button>
    </a-upload>

    <router-link
      :to="isDisabled.edit ? '/indicador' : '/indicador/' + idindicador + '/parametros'"
      class="text-center"
    >
      <div
        class="text-3xl"
        :class="[
          isDisabled.edit
            ? 'text-gray-400 hover:text-gray-400 cursor-not-allowed'
            : 'text-green-400'
        ]"
      >
        <ControlOutlined />
      </div>
      <p class="text-gray-800">Parámetros</p>
    </router-link>

    <a-dropdown>
      <div type="submit" class="text-center">
        <div class="text-3xl text-green-700">
          <MoreOutlined />
        </div>
        <p>Ver más</p>
      </div>

      <template #overlay>
        <a-menu>
          <!-- <a-menu-item class="text-center">
            <button class="text-center" @click="handlePeriodoMasivo">
              <div class="text-3xl text-blue-400">
                <HistoryOutlined />
              </div>
              <p>
                Procesar <br />
                masivo
              </p>
            </button>
          </a-menu-item> -->

          <a-menu-item class="text-center">
            <button @click="handleDelete" :disabled="isDisabled.deleteAndChange">
              <div
                class="text-3xl"
                :class="[
                  isDisabled.deleteAndChange ? 'text-gray-400 cursor-not-allowed' : 'text-red-400'
                ]"
              >
                <DeleteOutlined />
              </div>
              <p>Eliminar</p>
            </button>
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
  </div>
</template>

<script>
import {
  FileAddOutlined,
  FileZipOutlined,
  FormOutlined,
  DeleteOutlined,
  ControlOutlined,
  IssuesCloseOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
  MoreOutlined,
  HistoryOutlined
} from "@ant-design/icons-vue";
import { computed, ref, toRaw } from "vue";
import ApiDescargas from "@/api/descargas";
import { useStore } from "vuex";
import { UploadOutlined } from '@ant-design/icons-vue';
import { message, notification } from "ant-design-vue";


export default {
  props: {
    isDisabled: {
      type: Object,
      default() {
        return {
          edit: true,
          deleteAndChange: true
        };
      },
      required: true
    },
    indicador: {
      type: Object
    },
    objPrintIndicador: {
      type: Object,
      default: null
    }
  },
  components: {
    FileAddOutlined,
    FileZipOutlined,
    FormOutlined,
    DeleteOutlined,
    ControlOutlined,
    UploadOutlined,
    IssuesCloseOutlined,
    FileExcelOutlined,
    FilePdfOutlined,
    MoreOutlined,
    HistoryOutlined
  },
  emits: [
    "handleAdd",
    "handleEdit",
    "handleDelete",
    "handleChangeState",
    "handleCode",
    "generateReport",
    "handlePeriodoMasivo",
  ],
  setup(props, { emit }) {
    props.indicador;
    const store = useStore();
    const urlAction = process.env.VUE_APP_UPLOAD_API;

    const actionMessage = computed(() =>
      props.indicador?.activo === "1" ? "Ocultar" : "Publicar"
    );

    const idindicador = computed(() => {
      return props.indicador.idindicador;
    });

    const applyExportar = () => {
      const search = store.getters["indicador/search"];
      const filter = store.getters["indicador/filter"];

      ApiDescargas.getReporteIndicador({ search, filter }, "indicador", "REPORTE INDICADOR")
        .then(() => {})
        .catch((err) => console.log(err));
    };

    const beforeUpload = (file) => {
      const isZipOrCSV =
        file.type === "application/pdf"

      if (!isZipOrCSV) {
        notification.error({
          message: "Operación inválida",
          description: "Solo se aceptan archivos de tipo .pdf"
        });
      }
      return isZipOrCSV;
    };

    const onChangeUpload = (info) => {
      const status = info.file.status;

      if (status !== "uploading") {
        if (info.file.response?.errores?.length) {
          notification.error({
            message: "Operación inválida",
            description: "Error al cargar el archivo."
          });
        }
      }

      if (status === "done") {
        message.success(`${info.file.name} Archivo agregado correctamente.`);
      } else if (status === "error") {
        message.error(`${info.file.name} Falló el subir el archivo.`);
      }
    };

    return {
      actionMessage,
      handleAdd: () => emit("handleAdd"),
      handleCode: () => emit("handleCode"),
      handleEdit: () => emit("handleEdit"),
      handleDelete: () => emit("handleDelete"),
      handleChangeState: () => emit("handleChangeState"),
      handlePeriodoMasivo: () => emit("handlePeriodoMasivo"),
      applyExportar,
      fileList: ref([]),
      urlAction,
      beforeUpload,
      onChangeUpload,
      headers: {
        authorization: "Bearer " + store.getters.token
      },
      idindicador
    };
  }
};
</script>

<style></style>
