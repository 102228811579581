<template>
  <a-modal
    :visible="true"
    :title="isEdit ? 'Editar indicador' : 'Nuevo indicador'"
    class="max-w-sm md:max-w-2xl"
    width="100%"
    @ok="handleOk"
    @cancel="handleCancel"
    @close="closeModal"
    okText="Guardar"
  >
    <a-form :model="formState" :rules="rules" ref="formRef" layout="vertical" class="w-full">
      <div class="md:flex md:gap-5">
        <div class="w-full md:w-1/2">
          <a-form-item label="Nombre resumido" name="denominacion">
            <a-textarea v-model:value="formState.denominacion" />
          </a-form-item>

          <a-form-item label="Título numerador" name="titulonumerado">
            <a-textarea v-model:value="formState.titulonumerado" />
          </a-form-item>

          <a-form-item label="Título denominador" name="titulodenominado">
            <a-textarea v-model:value="formState.titulodenominado" />
          </a-form-item>

          <a-form-item label="Nombre tabla" name="sigla">
            <a-textarea v-model:value="formState.sigla" />
          </a-form-item>
        </div>

        <div class="w-full md:w-1/2">
          <a-form-item label="Título reporte" name="tituloreporte">
            <a-textarea v-model:value="formState.tituloreporte" />
          </a-form-item>

          <a-form-item label="Abreviatura" name="abreviatura">
            <a-input v-model:value="formState.abreviatura" />
          </a-form-item>

          <a-form-item label="Ficha Técnica" name="idtipofichatecnica">
            <a-select v-model:value="formState.idtipofichatecnica">
              <a-select-option value="" disabled="">Seleccione La ficha Técnica</a-select-option>
              <a-select-option
                :value="fichastec.idtipofichatecnica"
                v-for="fichastec in fichastecs"
                :key="fichastec.idtipofichatecnica"
              >
                {{ fichastec.denominacion }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="Grupo de indicador" name="idgrupo">
            <a-select v-model:value="formState.idgrupo">
              <a-select-option value="" disabled="">Seleccione el grupo</a-select-option>
              <a-select-option
                v-for="grupo in gruposInd"
                :value="grupo.idgrupo"
                :key="grupo.idgrupo"
              >
                {{ grupo.denominacion }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="Año" name="anio">
            <a-input v-model:value="formState.anio" placeholder="ejm. SUPERVISOR" />
          </a-form-item>
        </div>
      </div>
    </a-form>
  </a-modal>
</template>

<script>
import { reactive, onMounted, toRaw, ref } from "vue";
import { notification } from "ant-design-vue";
import { rules } from "./utilsIndicador";
import IndicadorApi from "@/api/indicador";
import QueryApi from "@/api/query";

export default {
  props: {
    indicador: {
      type: Object
    }
  },
  emits: ["closeModal", "fetchData"],
  setup(props, { emit }) {
    const modulos = ref([]);
    const formRef = ref();
    const fichastecs = ref([]);
    const gruposInd = ref([]);
    /* eslint-disable  vue/no-setup-props-destructure*/
    const indicador = props.indicador; // se define porque tiene que ser unico en editar
    const isEdit = !!props.indicador.idindicador;
    const denominacion = props.indicador.denominacion;

    const formState = reactive(indicador);

    const closeModal = () => {
      emit("closeModal");
    };

    const handleOk = () => {
      formRef.value
        .validate()
        .then(async () => {
          const payload = toRaw(formState);
          let response = {};
          let msgNotificacion = "";
          try {
            if (isEdit) {
              const isUnique = denominacion !== formState.denominacion;
              const idindicador = toRaw(formState)?.idindicador;

              response = await IndicadorApi.editOne(payload, idindicador, isUnique);
              msgNotificacion = "El indicador ha sido modificado correctamente.";
            } else {
              response = await IndicadorApi.addOne(payload);

              if (response.status == 200) {
                msgNotificacion =
                  "El indicador ha sido creado satisfactoriamente, se le ha reservado un espacio para su query SQL";
                var idindicador = response.data[0].id;

                if (idindicador) {
                  const response_2 = await QueryApi.addOneVacio(idindicador);

                  var idquery = response_2.data[0].id;

                  if (idquery) {
                    payload.idquery = idquery;
                    await IndicadorApi.editOne(payload, idindicador, false);
                  }
                }
              }
            }

            if (response.status == 200) {
              notification.success({
                message: "Operación exitosa",
                description: msgNotificacion
              });
            }
            emit("closeModal");
            emit("fetchData");
          } catch (error) {
            notification.error({
              message: "Operación inválida",
              description: error.message
            });
          }
        })
        .catch((err) => console.log(err));
    };

    const handleCancel = () => {
      emit("closeModal");
    };

    const fetchData = () => {
      IndicadorApi.getAllTipoFicha().then((response) => {
        fichastecs.value = response.data;
      });
      IndicadorApi.getAllGrupoInd().then((response) => {
        gruposInd.value = response.data;
      });
    };

    onMounted(() => {
      fetchData();
    });

    return {
      handleOk,
      closeModal,
      handleCancel,
      formState,
      fichastecs,
      gruposInd,
      modulos,
      formRef,
      isEdit,
      rules
    };
  }
};
</script>
