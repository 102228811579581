<template>
  <a-modal
    :visible="true"
    style="top: 20px"
    class="max-w-full md:px-40 px-3"
    width="100%"
    title="Editor de código"
    @ok="handleOk"
    @cancel="handleCancel"
    @close="closeModal"
    okText="Guardar"
  >
    <fieldset class="border border-solid border-gray-300 p-3">
      <a-form :model="formState" ref="formRef">
        <a-form-item label="Nombre">
          <a-input v-model:value="formState.query" placeholder="Nombre de la query SQL" />
        </a-form-item>
        <a-form-item label="" name="descripcion" style="margin-top: -15px;">
          <Codemirror
            v-model:value="formState.descripcion"
            border
            :options="cmOptions"
            placeholder="INSERT QUERY SQL"
            :height="450"
          />
        </a-form-item>

        <p style="margin-top: -30px;"></p>
      </a-form>
    </fieldset>
  </a-modal>
</template>

<script>
import { reactive, toRaw, ref } from "vue";
import { notification } from "ant-design-vue";
import QueryApi from "@/api/query";
import Codemirror from "codemirror-editor-vue3";
import "codemirror/mode/sql/sql.js";

export default {
  components: { Codemirror },
  props: {
    indicador: {
      type: Object
    }
  },
  emits: ["closeModal", "fetchData"],
  setup(props, { emit }) {
    /* eslint-disable vue/no-setup-props-destructure */

    const indicador = props.indicador;
    const formRef = ref();
    const formState = reactive(indicador);
    const denominacion = props.indicador.denominacion;

    const handleCancel = () => {
      emit("closeModal");
    };

    const closeModal = () => {
      emit("closeModal");
    };

    const handleOk = () => {
      formRef.value
        .validate()
        .then(async () => {
          const payload = toRaw(formState);
          let response = {};
          let msgNotificacion = "";

          try {
            const isUnique = denominacion !== formState.denominacion;
            const idquery = toRaw(formState)?.idquery;

            response = await QueryApi.editOneQuery(payload, idquery, isUnique);
            msgNotificacion = "La consulta ha sido modificado correctamente.";

            if (response.status == 200) {
              notification.success({
                message: "Operación exitosa",
                description: msgNotificacion
              });
            }
            emit("closeModal");
            emit("fetchData");
          } catch (error) {
            notification.error({
              message: "Operación inválida",
              description: error.message
            });
          }
        })
        .catch((err) => console.log(err));
    };

    return {
      closeModal,
      handleCancel,
      formState,
      handleOk,
      formRef,
      cmOptions: {
        mode: "text/x-mysql", // Language mode
        lineNumbers: true, // Show line number
        smartIndent: true, // Smart indent
        indentUnit: 2, // The smart indent unit is 2 spaces in length
        foldGutter: true, // Code folding
        styleActiveLine: true, // Display the style of the selected row
        extraKeys: { "Ctrl-Space": "autocomplete" }
      }
    };
  }
};
</script>
