<template>
  <a-form
    layout="horizontal"
    :model="formState"
    :rules="rules"
    ref="formRefFilter"
    class="md:flex md:flex-wrap w-full"
  >
    <div class="mb-2">
      <a-form-item label="Estado" name="filter" class="mb-2">
        <a-select v-model:value="formState.filter">
          <a-select-option value="">Todos</a-select-option>
          <a-select-option value="1">{{ estados[1] }}</a-select-option>
          <a-select-option value="0">{{ estados[0] }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="Buscar" class="mb-0" name="search">
        <a-input v-model:value="formState.search" />
      </a-form-item>
    </div>

    <div class="flex">
      <button @click="applyFilters" type="submit" class="text-center px-3">
        <div class="text-3xl text-blue-400">
          <SearchOutlined />
        </div>
        <p>Buscar</p>
      </button>

      <button @click="cleanFilters" type="button" class="text-center px-3" :disabled="isDisabled">
        <div
          class="text-3xl"
          :class="[isDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-purple-500']"
        >
          <ClearOutlined />
        </div>
        <p>Limpiar filtros</p>
      </button>
    </div>
  </a-form>
</template>
<script>
import { reactive, ref, toRaw } from "vue";
import { useStore } from "vuex";
import { SearchOutlined, ClearOutlined } from "@ant-design/icons-vue";

export default {
  components: {
    SearchOutlined,
    ClearOutlined
  },
  props: {
    estados: {
      type: Object,
      default: () => ({
        0: "Ocultado",
        1: "Publicado"
      })
    }
  },
  emits: ["reloadFetch", "setPageNumber"],
  setup(props, { emit }) {
    const store = useStore();
    const formRefFilter = ref();
    const isDisabled = ref(true);

    const formState = reactive({
      filter: store.getters["indicador/filter"],
      search: store.getters["indicador/search"]
    });

    const rules = {
      search: [
        {
          max: 50,
          message: "La cantidad máxima de caracteres es de 50",
          trigger: "blur"
        }
      ]
    };

    const applyFilters = () => {
      isDisabled.value = false;
      formRefFilter.value
        .validate()
        .then(async () => {
          const { filter, search } = toRaw(formState);
          store.dispatch("indicador/setFilter", filter);
          store.dispatch("indicador/setSearch", search);
          emit("setPageNumber", 1);
          emit("reloadFetch");
        })
        .catch((err) => console.log(err));
    };

    const cleanFilters = () => {
      isDisabled.value = true;
      formState.filter = "";
      formState.search = "";
      store.dispatch("indicador/cleanFilters");
      emit("setPageNumber", 1);
      emit("reloadFetch");
    };

    return {
      formState,
      applyFilters,
      formRefFilter,
      cleanFilters,
      rules,
      isDisabled
    };
  }
};
</script>
