/**
 * Contiene datos para las tablas y formularios
 */
export const columns = [
  {
    title: "N°",
    key: "index",
    width: 50,
    slots: { customRender: "rangepages" }
  },
  {
    title: "ID",
    dataIndex: "idindicador",
    key: "idindicador",
    slots: { customRender: "idindicador" },
    width: 50
  },
  {
    title: "Avance",
    dataIndex: "avance",
    key: "avance",
    slots: { customRender: "avance" },
    width: 70
  },
  {
    title: "Nombre resumido",
    dataIndex: "denominacion",
    key: "denominacion",
    width: 320,
    slots: { customRender: "denominacion" }
  },
  {
    title: "Grupo",
    dataIndex: "grupo",
    key: "grupo",
    width: 250
  },
  {
    title: "Abreviatura",
    dataIndex: "abreviatura",
    key: "abreviatura",
    width: 100,
    ellipsis: true
  },
  {
    title: "Año",
    dataIndex: "anio",
    key: "anio",
    width: 90
  },
  {
    title: "Estado",
    dataIndex: "activo",
    key: "activo",
    width: 100,
    slots: { customRender: "activo" }
  }
];

export const formRules = {
  denominacion: [
    {
      required: true,
      message: "El campo nombre no puede estar vacío",
      trigger: "blur"
    },
    {
      max: 50,
      message: "La cantidad máxima de caracteres es de 50",
      trigger: "blur"
    }
  ],
  description: [
    {
      required: true,
      message: "El campo valor no puede estar vacío",
      trigger: "blur"
    },
    {
      max: 200,
      message: "La cantidad máxima de caracteres es de 200",
      trigger: "blur"
    }
  ]
};

export const formLayout = {
  labelCol: {
    span: 7
  },
  wrapperCol: {
    span: 18,
    offset: 1
  }
};

export const columnsPeriodoEvaluacion = [
  {
    title: "N°",
    key: "index",
    width: 50,
    slots: { customRender: "rangepages" }
  },
  {
    title: "Fecha Inicio",
    dataIndex: "fechainicio_f",
    key: "fechainicio_f",
    width: 100
  },
  {
    title: "Fecha Fin",
    dataIndex: "fechafinal_f",
    key: "fechafinal_f",
    width: 100
  },
  {
    title: "Periodo",
    dataIndex: "rangoperiodo",
    key: "rangoperiodo",
    width: 100
  },
  {
    title: "Cantidad",
    dataIndex: "cantidad",
    key: "cantidad",
    width: 100
  },
  {
    title: "Estado",
    dataIndex: "ejecucion",
    key: "ejecucion",
    width: 100,
    slots: { customRender: "ejecucion" }
  }
];

export const columnsDetalle = [
  {
    title: "N°",
    key: "index",
    width: 35,
    slots: { customRender: "rangepages" }
  },
  {
    title: "Nro. Documento",
    dataIndex: "documento",
    key: "documento",
    width: 100
  },
  {
    title: "Nombre",
    dataIndex: "nombre",
    key: "nombre",
    width: 100
  },
  {
    title: "F. Nacimiento",
    dataIndex: "nacimiento",
    key: "nacimiento",
    width: 120
  },
  {
    title: "MicroRed",
    dataIndex: "microred",
    key: "microred",
    width: 150
  },
  {
    title: "Establecimiento",
    dataIndex: "establecimiento",
    key: "establecimiento",
    width: 180
  },
  {
    title: "ESTADO",
    dataIndex: "numerador",
    key: "numerador",
    slots: { customRender: "numerador" },
    width: 100
  }
];

// Graficas de barras
// https://codesandbox.io/s/vue-echartsshiyong-bfu3r?file=/src/components/constans.js:0-2502
export const optionsBar = (
  puntosEntrada = [],
  numerador = [],
  denominador = [],
  tendencia = [],
  max = 250
) => {
  return {
    title: {
      text: "Evolucion de indicador en el periodo de evaluación",
      x: "center",
      show: false
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        crossStyle: {
          color: "#999"
        }
      }
    },
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        magicType: { show: true, type: ["line", "bar"] },
        restore: { show: true },
        saveAsImage: { show: true }
      }
    },
    legend: {
      data: ["numerador", "denominador", "tendencia"],
      bottom: 20
    },

    xAxis: [
      {
        type: "category",
        data: puntosEntrada,
        axisPointer: {
          type: "shadow"
        },
        triggerEvent: true
      }
    ],
    yAxis: [
      {
        type: "value",
        name: "numerador",
        min: 0,
        max: max,
        axisLabel: {
          formatter: "{value}"
        },
        axisLine: { show: false },
        axisTick: { show: false },
        splitLine: {
          show: true,
          lineStyle: {
            type: "dashed"
          }
        }
      },
      {
        type: "value",
        name: "denominador",
        min: 0,
        max: max,
        axisLabel: {
          formatter: "{value}"
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: "dashed"
          }
        }
      }
    ],
    series: [
      {
        name: "numerador",
        type: "bar",
        barWidth: "10px",
        data: numerador,
        itemStyle: {
          color: "#4856c4"
        }
      },
      {
        name: "denominador",
        type: "bar",
        barWidth: "10px",

        itemStyle: {
          color: "#ffaf10"
        },
        data: denominador
      },
      {
        name: "tendencia",
        type: "line",
        yAxisIndex: 1,
        itemStyle: {
          color: "#77e355"
        },
        data: tendencia
      }
    ]
  };
};

export const columnsIndicadorEstablecimiento = [
  {
    title: "N°",
    key: "index",
    width: 50,
    slots: { customRender: "rangepages" }
  },
  {
    title: "PROVINCIA",
    dataIndex: "provincia",
    key: "provincia",
    sorter: (a, b) => a.provincia.localeCompare(b.provincia),
    sortDirections: ["descend", "ascend"],
    width: 120
  },
  {
    title: "DISTRITO",
    dataIndex: "distrito",
    key: "distrito",
    sorter: (a, b) => a.distrito.localeCompare(b.distrito),
    sortDirections: ["descend", "ascend"],
    width: 180
  },
  {
    title: "MICRORED",
    dataIndex: "microred",
    key: "microred",
    sorter: (a, b) => a.microred.localeCompare(b.microred),
    sortDirections: ["descend", "ascend"],
    width: 140
  },
  {
    title: "ESTABLECIMIENTO",
    dataIndex: "establecimiento",
    key: "establecimiento",
    sorter: (a, b) => a.establecimiento.localeCompare(b.establecimiento),
    sortDirections: ["descend", "ascend"],
    width: 180
  },
  {
    title: "AVANCE",
    key: "porcentaje",
    dataIndex: "porcentaje",
    width: 90,
    sorter: (a, b) => a.porcentaje - b.porcentaje,
    slots: { customRender: "porcentaje" }
  },
  {
    title: "NUM/DEN",
    key: "numeradordenominador",
    width: 50,
    slots: { customRender: "numeradordenominador" }
  }
];

export const rules = {
  denominacion: [
    {
      required: true,
      message: "El campo nombre no puede estar vacío",
      trigger: "blur"
    },
    {
      max: 600,
      message: "La cantidad máxima de caracteres es de 600",
      trigger: "blur"
    }
  ],
  titulonumerado: [
    {
      required: true,
      message: "El campo titulo numerador no puede estar vacío",
      trigger: "blur"
    },
    {
      max: 600,
      message: "La cantidad máxima de caracteres es de 600",
      trigger: "blur"
    }
  ],
  titulodenominado: [
    {
      required: true,
      message: "El campo titulo denominador no puede estar vacío",
      trigger: "blur"
    },
    {
      max: 600,
      message: "La cantidad máxima de caracteres es de 600",
      trigger: "blur"
    }
  ],
  tituloreporte: [
    {
      required: true,
      message: "El campo titulo reporte no puede estar vacío",
      trigger: "blur"
    },
    {
      max: 600,
      message: "La cantidad máxima de caracteres es de 350",
      trigger: "blur"
    }
  ],
  abreviatura: [
    {
      required: true,
      message: "El campo abreviatura no puede estar vacío",
      trigger: "blur"
    },
    {
      max: 30,
      message: "La cantidad máxima de caracteres es de 30",
      trigger: "blur"
    }
  ],
  idgrupo: [{ required: true, message: "El campo grupo debe ser seleccionado", trigger: "blur" }]
};

export const meses = [
  "ENE",
  "FEB",
  "MAR",
  "ABR",
  "MAY",
  "JUN",
  "JUL",
  "AGO",
  "SET",
  "OCT",
  "NOV",
  "DIC"
];

export const columnsIndicadorDistrito = [
  {
    title: "N°",
    key: "index",
    width: 40,
    slots: { customRender: "rangepages" }
  },
  {
    title: "PROVINCIA",
    dataIndex: "provincia",
    key: "provincia",
    sorter: (a, b) => a.provincia.localeCompare(b.provincia),
    sortDirections: ["descend", "ascend"],
    width: 120
  },
  {
    title: "DISTRITO",
    dataIndex: "distrito",
    key: "distrito",
    sorter: (a, b) => a.distrito.localeCompare(b.distrito),
    sortDirections: ["descend", "ascend"],
    width: 180
  },
  {
    title: "AVANCE",
    dataIndex: "porcentaje",
    key: "porcentaje",
    width: 90,
    sorter: (a, b) => b.porcentaje - a.porcentaje,
    defaultSortOrder: "ascend",
    slots: { customRender: "porcentaje" }
  },
  {
    title: "NUM/DEN",
    key: "numeradordenominador",
    width: 50,
    slots: { customRender: "numeradordenominador" }
  }
];
